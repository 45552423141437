import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../Assets/homepage/logo.svg';
import downArrow from '../../Assets/homepage/chevron-down.svg';
import upArrow from '../../Assets/homepage/chevron-up.svg';
import './MobileNav.css';

const MobileNav = () => {
    const navigate = useNavigate();
    const [navOpen, setNavOpen] = useState(false);
    const [solutionsOpen, setSolutionsOpen] = useState(false);
    const [resourcesOpen, setResourcesOpen] = useState(false);

    // Function to toggle mobile navigation
    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    const toggleSolutionsDropdown = () => {
        setSolutionsOpen(!solutionsOpen);
    };

    const toggleResourcesDropdown = () => {
        setResourcesOpen(!resourcesOpen);
    };

    return (
        <>
            {/* Hamburger Icon */}
            <div className="mobile-nav-trigger">
                <img src={logo} alt="Logo" className="h-10 cursor-pointer" onClick={() => navigate('/')} />
                <div className={`hamburger ${navOpen ? 'open' : ''}`} onClick={toggleNav}>
                    <span className='span1'></span>
                    <span className='span2'></span>
                    <span className='span3'></span>
                </div>
            </div>

            {/* Mobile Navigation Menu */}
            <div className={`mobile-nav ${navOpen ? 'open' : ''}`}>
                <div className="mobile-nav-header">
                    <img src={logo} alt="Logo" className="h-12" onClick={() => navigate('/')} />
                    <div className="close-nav" onClick={toggleNav}>&times;</div>
                </div>

                <nav className="mobile-nav-links">
                    <Link to="/features" className='text-blue' onClick={toggleNav}>Features</Link>

                    <div className="dropdown">
                        <div className="dropdown-head text-blue" onClick={toggleSolutionsDropdown}>
                            <span>Solutions</span>
                            <img src={solutionsOpen ? upArrow : downArrow} alt="Toggle Arrow" className="arrow-icon" />
                        </div>
                        {solutionsOpen && (
                            <div className="dropdown-data">
                                <Link to="/people-management" className='text-blue' onClick={toggleNav}>People Management</Link>
                                <Link to="/time-attendance" className='text-blue' onClick={toggleNav}>Time & Attendance</Link>
                                <Link to="/leave-management" className='text-blue' onClick={toggleNav}>Leave Management</Link>
                                <Link to="/payroll-management" className='text-blue' onClick={toggleNav}>Payroll Management</Link>
                                <Link to="/remote-location-management" className='text-blue' onClick={toggleNav}>Remote Location</Link>
                            </div>
                        )}
                    </div>

                    <Link to="/pricing" className='text-blue' onClick={toggleNav}>Pricing</Link>

                    <div className="dropdown">
                        <div className="dropdown-head text-blue" onClick={toggleResourcesDropdown}>
                            <span>Resources</span>
                            <img src={resourcesOpen ? upArrow : downArrow} alt="Toggle Arrow" className="arrow-icon" />
                        </div>
                        {resourcesOpen && (
                            <div className="dropdown-data">
                                {/* <Link rel='noopener noreferrer' to="/blog" className='text-blue' onClick={toggleNav}>Blogs</Link> */}
                                <Link to="/about" className='text-blue' onClick={toggleNav}>About</Link>
                                <Link to="/faqs" className='text-blue' onClick={toggleNav}>FAQs</Link>
                            </div>
                        )}
                    </div>
                    <Link target='_black' to="https://app.tokentalent.co" className='text-blue' onClick={toggleNav}>Login</Link>
                    <Link target='_blank' to="https://app.tokentalent.co/signup" rel='noopener noreferrer' className='text-darkRed' onClick={toggleNav}>Get Started</Link>
                </nav>
            </div>
        </>
    );
};

export default MobileNav;
