import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const AboutPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const moveToDemo = () => {
        window.open('https://calendly.com/demofortokentalent/token-talent-demo', '_blank');
    };

    const featureItems = [
        {
            title: 'Support',
            image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f1a197859a6cd7f265_image%203.png',
            description: 'We prioritize customer support, offering reliable assistance every step of the way.',
        },
        {
            title: 'Organize',
            image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/63915859fa889834c4f1ff92_image%203-2.png',
            description: 'We streamline processes for efficiency and clarity in all operations.',
        },
        {
            title: 'Flexibility',
            image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639158510667812dff08e1af_image%203-4.png',
            description: 'We adapt quickly to change, fostering innovation and growth.',
        },
        {
            title: 'Speed',
            image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/6391585b7b7cd87baef5e9ec_image%203-1.png',
            description: 'We deliver prompt solutions to meet evolving customer needs.',
        },
        {
            title: 'Quality',
            image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639158557ac2b528531836f1_image%203-3.png',
            description: 'We maintain the highest standards in all our products and services.',
        },
        {
            title: 'Resource',
            image: 'https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f3db4f4b8767c499ba_image%203-5.png',
            description: 'We optimize resource use for sustainable growth and impact.',
        },
    ];

    return (
        <div className="bg-gray-50">
            <section className="container mx-auto px-4 py-12 md:py-24">
                <div className="bg-indigo-900 rounded-2xl shadow-lg shadow-indigo-500 p-8 md:p-12 lg:p-16">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4">Unlocking HR's Full Potential with Token Talent</h1>
                    <h2 className="text-xl md:text-2xl font-bold text-indigo-200 mb-8">Looking to take your HR employees to a state of Token Talent?</h2>
                    <div className="flex flex-col sm:flex-row gap-4">
                        <Link target='_blank' to="https://app.tokentalent.co/signup" className="bg-indigo-700 hover:bg-indigo-600 text-white font-semibold py-3 px-6 rounded-md text-center">
                            Get Started
                        </Link>
                        <button onClick={moveToDemo} className="bg-white text-indigo-700 font-semibold py-3 px-6 rounded-md border border-indigo-700 hover:bg-indigo-50">
                            Let's Meet
                        </button>
                    </div>
                </div>
            </section>

            <section className="container mx-auto px-4 py-12 md:py-24">
                <div className="flex flex-col lg:flex-row items-center gap-12">
                    <div className="lg:w-1/2">
                        <h3 className="text-2xl md:text-3xl font-bold mb-4">About Us</h3>
                        <div className="w-16 border-t-2 border-indigo-500 mb-6"></div>
                        <p className="text-gray-700 mb-4">
                            Token Talent is revolutionizing HR with our innovative platform designed for the modern workplace. We offer localized solutions covering labor laws, expat regulations, social insurance, and tax laws across the MENA region.
                        </p>
                        <p className="text-gray-700 mb-4">
                            Our cloud-based HRMS streamlines operations with automated salary calculations and Employee Self Service (ESS) features, putting employees first and simplifying processes for all.
                        </p>
                        <p className="text-gray-700">
                            Driven by our core values, we're committed to exceeding expectations and enhancing the employee experience, always staying ahead of the latest HR trends.
                        </p>
                    </div>
                    <div className="lg:w-1/2">
                        <img src="https://images.unsplash.com/photo-1541746972996-4e0b0f43e02a?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Token Talent office" className="w-full h-auto rounded-lg shadow-lg" />
                    </div>
                </div>
            </section>
            <section className="bg-indigo-100 py-12 md:py-24">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Our Core Values</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {featureItems.map((item, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                                <img src={item.image} alt={item.title} className="w-12 h-12 mb-4" />
                                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                                <p className="text-gray-600">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="container mx-auto px-4 py-12 md:py-24">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">Join the Token Talent Team</h2>
                <p className="text-center text-gray-700 max-w-2xl mx-auto mb-12">
                    We're a dynamic team dedicated to revolutionizing HR with cutting-edge technology. If you want to make a real difference in the HR world, Token Talent is the place for you.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="bg-indigo-100 p-6 rounded-lg hover:bg-indigo-200 transition-colors">
                        <img src="https://assets.website-files.com/6357722e2a5f19121d37f84d/6358bda43ea08a612343b1f3_Vector-3.svg" alt="" className="w-12 h-12 mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Flexible Workplace</h3>
                        <p className="text-gray-600">We focus on results, offering flexibility in how you work.</p>
                    </div>
                    <div className="bg-indigo-100 p-6 rounded-lg hover:bg-indigo-200 transition-colors">
                        <img src="https://assets.website-files.com/6357722e2a5f19121d37f84d/6358bdb1bd05f5915d7bf31c_Vector-4.svg" alt="" className="w-12 h-12 mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Learn From the Best</h3>
                        <p className="text-gray-600">Work alongside industry experts and innovators.</p>
                    </div>
                    <div className="bg-indigo-100 p-6 rounded-lg hover:bg-indigo-200 transition-colors">
                        <img src="https://assets.website-files.com/6357722e2a5f19121d37f84d/6358bdaaeeb5cbd611bf5048_Vector-5.svg" alt="" className="w-12 h-12 mb-4" />
                        <h3 className="text-xl font-semibold mb-2">Employee Benefits</h3>
                        <p className="text-gray-600">Enjoy comprehensive benefits and perks package.</p>
                    </div>
                </div>
            </section>
            <div className="text-center mb-8">
                <button onClick={() => navigate("/hiring")} className=" bg-blue hover:bg-indigo-700 text-white font-semibold py-3 px-8 rounded-lg text-sm transition-colors">
                    We're Hiring
                </button>
            </div>
        </div>
    )
}

export default AboutPage